<template>
  <div class="wordDetail" v-if="info.id">
    <a-button class="gmb-10" @click="$router.back()">返回</a-button>
    <a-row :gutter="20">
      <a-col :span="8" class="leftDetail">
        <div class="block leftWrap">
          <a-spin :spinning="loading">
            <div class="name gmb-10 ml-40">{{info.name}}</div>
            <div class="gmb-10 ml-40">
              <span>英</span>
              <span class="gmr-5">{{info.e_pronu}}</span>
              <a-icon type="sound" style="color:pink" @click="playAudio(info.e_voice)" theme="filled" />
            </div>
            <div class="gmb-10 ml-40">
              <span>美</span>
              <span class="gmr-5">{{info.a_pronu}}</span>
              <a-icon type="sound" style="color:pink" @click="playAudio(info.a_voice)" theme="filled" />
            </div>
            <div class="gmb-10 ml-40 sortList">
              <div class="sortItem">
                <span>{{info.word_morphology_name && info.word_morphology_name.join('/')}}</span>
              </div>
            </div>
            <div class="gmb-10 ml-40 sortList">
              <div class="sortItem">
                <span>{{info.word_classification_name && info.word_classification_name.join('/')}}</span>
              </div>
            </div>
            <div class="meanBox" style="margin-top:20px;">
              <a-row class="meanItem gmb-10" :gutter="10" v-for="(item,index) in info.word_explain_list" :key="index">
                <a-col :span="2" style="text-align:right">
                  {{item.word_type}}
                </a-col>
                <a-col :span="22">
                  {{item.word_translate}}
                </a-col>
              </a-row>
            </div>
          </a-spin>
        </div>
      </a-col>
      <a-col :span="16" class="rightDetail">
        <div class="block">
          <div class="ztTitle">真题例句</div>
          <div class="ztList" v-if="hasWrodRole">
            <div class="ztItem" v-for="(item,index) in info.word_examples_list" :key="index">
              <div class="ztName gmb-10">
                <span class="name">试卷名称 {{item.title}}</span>
              </div>
              <div class="ztContent">
                {{item.content}}
              </div>
            </div>
          </div>
          <div class="gtc" v-else>
            <div class="mask gmv-10"></div>
            <a-button type="primary" @click="$router.push('/personal/vip')">开通会员</a-button>
          </div>
        </div>
      </a-col>
    </a-row>
    <audio :src="curAudio" id="audioMusic"></audio>
  </div>
</template>
<script>
import { getWordInfo } from "@/api/wordLib";
import { mapGetters } from "vuex";
import { getAudioUrl } from "@/utils/common";
export default {
  data() {
    return {
      param: {
        id: "",
        page: 1,
        limit: 10,
      },
      info: {},
      loading: true,
      curAudio: "",
    };
  },
  computed: {
    ...mapGetters(["hasWrodRole"]),
  },
  created() {
    this.param.id = this.$route.query.id;
    this.getWordInfo();
  },
  methods: {
    //播放
    playAudio(src) {
      this.curAudio = getAudioUrl(src);
      setTimeout(() => {
        audioMusic.play();
      });
    },
    getWordInfo() {
      this.loading = true;
      getWordInfo(this.param.id).then((data) => {
        this.loading = false;
        if (data.code == 200) {
          let _data = data.data;
          this.info = _data;
          this.info.a_voice = getAudioUrl(_data.a_voice);
          this.info.e_voice = getAudioUrl(_data.e_voice);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.block {
  background: #fff;
  margin-bottom: 10px;
  padding: 24px;
  height: 750px;
}
.mask {
  width: 100%;
  height: 200px;
  background: linear-gradient(#fff, #565050);
}
.wordDetail {
  color: #000;
  .ml-40 {
    margin-left: 40px;
  }
  .leftDetail {
    .leftWrap {
      position: relative;
      padding-bottom: 50px !important;
    }
    .name {
      font-size: 20px;
      color: #000;
      font-weight: 700;
    }
    .sortList {
      display: flex;
    }
  }
  .rightDetail {
    position: relative;
    .ztTitle {
      font-size: 30px;
    }
    .ztList {
      height: 630px;
      overflow-y: scroll;
      .ztItem {
        border-bottom: 1px solid #999;
        padding: 15px 0;
        .nameType {
          padding: 3px 5px;
          background: rgb(24, 144, 255);
          margin-right: 10px;
          color: #fff;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>